.meter {
    width: 100%;
    height: 1px;
    position: relative;
    background: rgb(216, 216, 216);
}
.meter > span {
    display: block;
    text-align: center;
    height: 100%;
    font-size: 0.9em;
    background-color: rgb(167, 167, 167);
    position: relative;
    overflow: hidden;
}

.dropdown-toggle::after {
    display: none;
}