svg {
	position: absolute;
}

.circle {
    stroke-dasharray: 900;
    stroke-dashoffset: 900;
    animation: stroke 1.5s ease-in forwards;
}

@keyframes stroke {
    to {
        stroke-dashoffset: 0;
    }
}
